/* Change this file to get your personal Porfolio */

// Website related settings
const settings = {
  isSplash: false, // Change this to false if you don't want Splash screen.
};

//SEO Related settings
const seo = {
  title: "Moses Ochacha",
  description:
    "A passionate individual who always thrives to work on end to end products which develop sustainable and scalable social and technical systems to create impact.",
  og: {
    title: "Moses Portfolio",
    type: "website",
    url: "http://https://github.com/Mosesochacha/",
  },
};

//Home Page
const greeting = {
  title: "Moses Ochacha",
  logo_name: "Mosesochacha",
  nickname: "Software Engineer",
  subTitle:
    "I'm a Full Stack Developer proficient in Node.js, React, Ruby, JavaScript, PostgreSQL, HTML, CSS, and Tailwind CSS. With expertise in both front-end and back-end development, I create well-contained products. My skills enable me to develop sustainable and scalable systems, ensuring seamless user experiences from conception to deployment, fostering impactful solutions.",
  resumeLink:
    "https://drive.google.com/file/d/1KpKfbbJT7ZckeNC78RNBmTLQ3MUpAgge/view?usp=sharing",
  portfolio_repository: "https://github.com/Mosesochacha",
  githubProfile: "https://github.com/Mosesochacha",
};



const socialMediaLinks = [
  {
    name: "Github",
    link: "https://github.com/Mosesochacha",
    fontAwesomeIcon: "fa-github", // Reference https://fontawesome.com/icons/github?style=brands
    backgroundColor: "#181717", // Reference https://simpleicons.org/?q=github
  },
  {
    name: "LinkedIn",
    link: "https://www.linkedin.com/in/moses-ochacha-a21a19261/",
    fontAwesomeIcon: "fa-linkedin-in", // Reference https://fontawesome.com/icons/linkedin-in?style=brands
    backgroundColor: "#0077B5", // Reference https://simpleicons.org/?q=linkedin
  },

  {
    name: "Gmail",
    link: "mailto:ochachamoses443@gmail.com",
    fontAwesomeIcon: "fa-google", // Reference https://fontawesome.com/icons/google?style=brands
    backgroundColor: "#D14836", // Reference https://simpleicons.org/?q=gmail
  },
];
// What I do
// fileName: "",

const skills = {
  data: [
    {
      title: "Full Stack Development",
      fileName: "FullStackImg",
      skills: [
        "⚡ Building responsive website front end using React",
        "⚡ Creating application backends using Node.js, Express, and Flask",
        "⚡ Designing and implementing RESTful APIs for seamless communication",
        "⚡ Integrating and managing databases such as PostgreSQL and MySQL",
        "⚡ Implementing user authentication and authorization using JWT and OAuth",
      ],
      softwareSkills: [
        {
          skillName: "HTML5",
          fontAwesomeClassname: "simple-icons:html5",
          style: {
            color: "#E34F26",
          },
        },
        {
          skillName: "CSS3",
          fontAwesomeClassname: "fa-css3",
          style: {
            color: "#1572B6",
          },
        },
        {
          skillName: "Sass",
          fontAwesomeClassname: "simple-icons:sass",
          style: {
            color: "#CC6699",
          },
        },
        {
          skillName: "JavaScript",
          fontAwesomeClassname: "simple-icons:javascript",
          style: {
            backgroundColor: "#000000",
            color: "#F7DF1E",
          },
        },
        {
          skillName: "ReactJS",
          fontAwesomeClassname: "simple-icons:react",
          style: {
            color: "#61DAFB",
          },
        },
        {
          skillName: "Node.js",
          fontAwesomeClassname: "simple-icons:node-dot-js",
          style: {
            color: "#339933",
          },
        },
        {
          skillName: "NPM",
          fontAwesomeClassname: "simple-icons:npm",
          style: {
            color: "#CB3837",
          },
        },
        {
          skillName: "Yarn",
          fontAwesomeClassname: "simple-icons:yarn",
          style: {
            color: "#2C8EBB",
          },
        },
     
      
      ],
    },
    {
      title: "Freelancing",
      fileName: "FreelanceImg",
      skills: [
        "⚡ Developing server-side applications using Node.js and Express",
        "⚡ Creating dynamic user interfaces with React.js",
        "⚡ Managing and querying databases like PostgreSQL and MySQL",
        "⚡ Version control and collaboration using Git and GitHub",
        "⚡ Deploying applications and websites using various methods",
      ],
      softwareSkills: [
        {
          skillName: "Node.js",
          fontAwesomeClassname: "simple-icons:node-dot-js",
          style: {
            color: "#339933",
          },
        },
        {
          skillName: "React.js",
          fontAwesomeClassname: "simple-icons:react",
          style: {
            color: "#61DAFB",
          },
        },
        {
          skillName: "PostgreSQL",
          fontAwesomeClassname: "simple-icons:postgresql",
          style: {
            color: "#336791",
          },
        },
        {
          skillName: "MySQL",
          fontAwesomeClassname: "simple-icons:mysql",
          style: {
            color: "#4479A1",
          },
        },
        {
          skillName: "GitHub",
          fontAwesomeClassname: "simple-icons:github",
          style: {
            color: "#181717",
          },
        },
        {
          skillName: "HTML5",
          fontAwesomeClassname: "simple-icons:html5",
          style: {
            color: "#E34F26",
          },
        },
        {
          skillName: "CSS3",
          fontAwesomeClassname: "fa-css3",
          style: {
            color: "#1572B6",
          },
        },
        {
          skillName: "JavaScript",
          fontAwesomeClassname: "simple-icons:javascript",
          style: {
            backgroundColor: "#000000",
            color: "#F7DF1E",
          },
        },
        // Add any other skills here
      ],
    },
    {
      title: "UI/UX Design",
      fileName: "DesignImg",
      skills: [
        "⚡ Designing highly attractive user interfaces for mobile and web applications",
        "⚡ Customizing logo designs and creating logos from scratch",
        "⚡ Creating application workflows to optimize user experience",
        "⚡ Integrating Twilio for communication features",
        "⚡ Utilizing Trello for project management and collaboration",
      ],
      softwareSkills: [
        {
          skillName: "Twilio",
          fontAwesomeClassname: "simple-icons:twilio",
          style: {
            color: "#F22F46",
          },
        },
        {
          skillName: "Trello",
          fontAwesomeClassname: "simple-icons:trello",
          style: {
            color: "#0079BF",
          },
        },
     
        {
          skillName: "Figma",
          fontAwesomeClassname: "simple-icons:figma",
          style: {
            color: "#F24E1E",
          },
        },
      
        // {
        //   skillName: "Inkscape",
        //   fontAwesomeClassname: "simple-icons:inkscape",
        //   style: {
        //     color: "#000000",
        //   },
        // },
      ],
    },
    {
      title: "CI/CD",
      fileName: "stanford_logo.png",
      skills: [
        "⚡ Setting up Continuous Integration and Continuous Deployment (CI/CD) pipelines",
        "⚡ Automating testing and deployment processes",
        "⚡ Utilizing tools like  Travis CI, and GitHub Actions",
      ],
      softwareSkills: [
   
        {
          skillName: "Travis CI",
          fontAwesomeClassname: "simple-icons:travisci",
          style: {
            color: "#3EAAAF",
          },
        },
        {
          skillName: "GitHub Actions",
          fontAwesomeClassname: "simple-icons:githubactions",
          style: {
            color: "#2088FF",
          },
        },
        // Add more CI/CD skills as needed
      ],
    },
  ],
};


// Education Page
const competitiveSites = {
  competitiveSites: [
    {
      siteName: "LeetCode",
      iconifyClassname: "simple-icons:leetcode",
      style: {
        color: "#F79F1B",
      },
      profileLink: "https://leetcode.com/layman_brother/",
    },
    {
      siteName: "HackerRank",
      iconifyClassname: "simple-icons:hackerrank",
      style: {
        color: "#2EC866",
      },
      profileLink: "https://www.hackerrank.com/layman_brother",
    },
    {
      siteName: "Codechef",
      iconifyClassname: "simple-icons:codechef",
      style: {
        color: "#5B4638",
      },
      profileLink: "https://www.codechef.com/users/moses123",
    },
    {
      siteName: "Codeforces",
      iconifyClassname: "simple-icons:codeforces",
      style: {
        color: "#1F8ACB",
      },
      profileLink: "http://codeforces.com/profile/layman_brother",
    },
    {
      siteName: "Hackerearth",
      iconifyClassname: "simple-icons:hackerearth",
      style: {
        color: "#323754",
      },
      profileLink: "https://www.hackerearth.com/@moses391",
    },
    {
      siteName: "Kaggle",
      iconifyClassname: "simple-icons:kaggle",
      style: {
        color: "#20BEFF",
      },
      profileLink: "https://www.kaggle.com/laymanbrother",
    },
  ],
};

const degrees = {
  degrees: [
    {
      title: "Moringa School of Technology",
      subtitle: "Cert. in Software Engineering",
      logo_path: "",
      alt_name: " Moringa School",
      duration: "2022 - 2023",
      descriptions: [
        "⚡Group Lead Scrum Master⚡ : As a Group Lead Scrum Master, I led cross-functional teams in Agile software development projects, facilitating collaboration and ensuring adherence to Scrum principles. I played a pivotal role in project planning, backlog grooming, sprint execution, and ensuring the team's productivity..",
        "⚡ Full Stack Development: I actively engaged in Full Stack Development, gaining proficiency in both frontend and backend technologies. I developed web applications, integrating front-end frameworks and databases to create seamless user experiences.",
        "⚡ Advanced Coursework: In addition to the core curriculum, I pursued advanced courses in Deep Learning new tech,  enhancing my skills in emerging technologies.",
        "⚡ Scholarship Recognition: My consistent academic performance earned me the prestigious Merit cum Means Scholarship, a recognition presented to the top 10% of students at the college. I was honored to receive this award from our esteemed student Support Team        s.",
      ],
      website_link: "https://moringaschool.com/",
    },
  ],
};

const certifications = {
  certifications: [
    {
      title: "Machine Learning",
      subtitle: "- Andrew Ng",
      logo_path: "stanford_logo.png",
      certificate_link:
        "https://www.coursera.org/account/accomplishments/verify/22MTSSC5WDTM",
      alt_name: "Stanford University",
      color_code: "#8C151599",
    },
    {
      title: "Deep Learning",
      subtitle: "- Andrew Ng",
      logo_path: "deeplearning_ai_logo.png",
      certificate_link:
        "https://www.coursera.org/account/accomplishments/specialization/H8CPSFXAJD2G",
      alt_name: "deeplearning.ai",
      color_code: "#00000099",
    },
    {
      title: "ML on GCP",
      subtitle: "- GCP Training",
      logo_path: "google_logo.png",
      certificate_link:
        "https://www.coursera.org/account/accomplishments/specialization/EB4VJARK8647",
      alt_name: "Google",
      color_code: "#0C9D5899",
    },
    {
      title: "Data Science",
      subtitle: "- Alex Aklson",
      logo_path: "ibm_logo.png",
      certificate_link:
        "https://www.coursera.org/account/accomplishments/specialization/PLEAPCSJBZT5",
      alt_name: "IBM",
      color_code: "#1F70C199",
    },
    {
      title: "Big Data",
      subtitle: "- Kim Akers",
      logo_path: "microsoft_logo.png",
      certificate_link:
        "https://drive.google.com/file/d/164zKCFOsI4vGqokc-Qj-e_D00kLDHIrG/view",
      alt_name: "Microsoft",
      color_code: "#D83B0199",
    },
    {
      title: "Advanced Data Science",
      subtitle: "- Romeo Kienzler",
      logo_path: "ibm_logo.png",
      certificate_link:
        "https://www.coursera.org/account/accomplishments/verify/BH2T9BRU87BH",
      alt_name: "IBM",
      color_code: "#1F70C199",
    },
    {
      title: "Advanced ML on GCP",
      subtitle: "- GCP Training",
      logo_path: "google_logo.png",
      certificate_link:
        "https://www.coursera.org/account/accomplishments/verify/5JZZM7TNQ2AV",
      alt_name: "Google",
      color_code: "#0C9D5899",
    },
    {
      title: "DL on Tensorflow",
      subtitle: "- Laurence Moroney",
      logo_path: "deeplearning_ai_logo.png",
      certificate_link:
        "https://www.coursera.org/account/accomplishments/verify/6T4DCUGNK8J8",
      alt_name: "deeplearning.ai",
      color_code: "#00000099",
    },
    {
      title: "Fullstack Development",
      subtitle: "- Jogesh Muppala",
      logo_path: "coursera_logo.png",
      certificate_link:
        "https://www.coursera.org/account/accomplishments/certificate/NRANJA66Y2YA",
      alt_name: "Coursera",
      color_code: "#2A73CC",
    },
    {
      title: "Kuberenetes on GCP",
      subtitle: "- Qwiklabs",
      logo_path: "gcp_logo.png",
      certificate_link:
        "https://google.qwiklabs.com/public_profiles/e4d5a92b-faf6-4679-a70b-a9047c0cd750",
      alt_name: "GCP",
      color_code: "#4285F499",
    },
    {
      title: "Cryptography",
      subtitle: "- Saurabh Mukhopadhyay",
      logo_path: "nptel_logo.png",
      certificate_link:
        "https://drive.google.com/open?id=1z5ExD_QJVdU0slLkp8CBqSF3-C3g-ro_",
      alt_name: "NPTEL",
      color_code: "#FFBB0099",
    },
    {
      title: "Cloud Architecture",
      subtitle: "- Qwiklabs",
      logo_path: "gcp_logo.png",
      certificate_link:
        "https://google.qwiklabs.com/public_profiles/5fab4b2d-be6f-408c-8dcb-6d3b58ecb4a2",
      alt_name: "GCP",
      color_code: "#4285F499",
    },
  ],
};

// Experience Page
const experience = {
  title: "Dynamic Developer",
  subtitle: "Innovating Tech Solutions",
  description: "As a Full Stack Developer, I've honed my skills at Cinab and in software consultancy, specializing in both front-end and back-end development. At Cinab, I worked on a delivery app for drivers and maintained their e-commerce website. My passion for community development is evident through my volunteer work at I AM HESHIMA. My portfolio includes projects like Chelcko, a portfolio site for a mechanical installation company. With a solid Software Engineering background from Moringa School, I am dedicated to driving organizational growth through innovative solutions and user-centric design.",
  header_image_path: "experience.svg",
  sections: [
    {
      title: "Work",
      work: true,
      experiences: [
        {
          title: "Volunteerships",
          company: "Iam Heshima",
          company_url: "https://www.iamheshima.org",
          logo_path: "legato_logo.png",
          duration: "June 2023",
          location: "Nairobi, Kenya",
          description:
            " Spearheading the development of a comprehensive school management system.Implementing innovative features to streamline administrative tasks and enhance user experience",
          color: "#0879bf",
        },
        {
          title: "Cash Flow",
          company: "Moringa School",
          company_url: "https://www.moringaschool.com",
          logo_path: "muffito_logo.png",
          duration: "May 2023 - june 2023",
          location: "Ngong Lane Plaze",
          description:
            "Led the creation of a dynamic web application for seamless money transfers. Collaborated within a team to design the software architecture for both mobile responsive and web pcs, along with an intuitive admin panel.    Contributed to building a robust system to manage and monitor financial transactions effectively.  ",
          color: "#9b1578",
        },
        {
          title: "Full Stack-development",
          company: "Cinab Online.",
          company_url: "http://cinab.co.ke/",
          logo_path: "cinab.jpeg",
          duration: "2023 - 2024",
          location: "Nairobi , Kenya",
          description:
            "As a Full Stack Developer at Cinab, I thrive in a dynamic environment where I refine my skills in both front-end and back-end development. Engaged in diverse projects, I actively contribute to designing and implementing scalable solutions, leveraging expertise in various programming languages and frameworks. Through hands-on experience, I gain invaluable insights into the software development lifecycle, collaborating with cross-functional teams to deliver innovative and efficient solutions. My tenure at Cinab has equipped me with a robust foundation for tackling real-world challenges and fueled my passion for creating impactful, user-centric applications.",
          color: "#fc1f20",
        },
      ],
    },
    {
      title: "Internships",
      experiences: [
        {
          title: "Fullstack-developer Intern",
          company: "Italanta",
          company_url: "https://italanta.net/",
          logo_path: "italanta.png",
          duration: "May 2023 - July 2023",
          location: "Nairobi, Kenya",
          description:
            "Developing products, apps and the teams to grow.",
          color: "#000000",
        },
    
      ],
    },
    // {
    //   title: "Volunteerships",
    //   experiences: [
    //     {
    //       title: "Google Explore ML Facilitator",
    //       company: "Google",
    //       company_url: "https://about.google/",
    //       logo_path: "google_logo.png",
    //       duration: "June 2019 - April 2020",
    //       location: "Hyderabad, Telangana",
    //       description:
    //         "Explore Machine Learning (ML) is a Google-sponsored program for university students to get started with Machine Learning. The curriculum offers 3 tracks of ML Content (Beginner, Intermediate, Advanced) and relies on university student facilitators to train other students on campus and to build opensource projects under this program.",
    //       color: "#4285F4",
    //     },
    //     {
    //       title: "Microsoft Student Partner",
    //       company: "Microsoft",
    //       company_url: "https://www.microsoft.com/",
    //       logo_path: "microsoft_logo.png",
    //       duration: "Aug 2019 - May 2020",
    //       location: "Hyderabad, Telangana",
    //       description:
    //         "Microsoft Student Partner is a program for university students to lead the awareness and use of Cloud especially Azure tools in the development of their projects and startups. Under this program, I have organised hands on workshops and seminars to teach Cloud Computing concepts to students.",
    //       color: "#D83B01",
    //     },
    //     {
    //       title: "Mozilla Campus Captain",
    //       company: "Mozilla",
    //       company_url: "https://www.mozilla.org/",
    //       logo_path: "mozilla_logo.png",
    //       duration: "Oct 2019 - May 2020",
    //       location: "Kurnool, Andhra Pradesh",
    //       description:
    //         "My responsibility for this program was to create opensource environment in college and in the city. We have organised multiple hackathons on the problems collected by ordinary people from Kurnool city. We have build opensource community of our own college. The community is available at dsc_iiitdmk on github.",
    //       color: "#000000",
    //     },
    //     {
    //       title: "Developer Students Club Member",
    //       company: "DSC IIITDM Kurnool",
    //       company_url:
    //         "https://www.linkedin.com/company/developer-students-club-iiitdm-kurnool",
    //       logo_path: "dsc_logo.png",
    //       duration: "Jan 2018 - May 2020",
    //       location: "Kurnool, Andhra Pradesh",
    //       description:
    //         "We have well established developer club in college which is directly associated with Google Developers. We have developed many interdisciplinary projects under the membership of this club. We have organised workshops and activities on Android Application Development, Flutter and React JS.",
    //       color: "#0C9D58",
    //     },
    //     {
    //       title: "Developer Program Member",
    //       company: "Github",
    //       company_url: "https://github.com/",
    //       logo_path: "github_logo.png",
    //       duration: "July 2019 - PRESENT",
    //       location: "Work From Home",
    //       description:
    //         "I am actively contributing to many opensource projects. I have contributed to projects of organisations like Tensorflow, Uber, Facebook, Google, Scikit-learn, Kiwix, Sympy, Python, NVLabs, Fossasia, Netrack, Keras etc. These contributions include bug fixes, feature requests and formulating proper documentation for project.",
    //       color: "#181717",
    //     },
    //   ],
    // },
  ],
};

// Projects Page
const projectsHeader = {
  title: "Projects",
  description:
    "My portfolio encompasses a diverse array of projects that utilize a wide variety of cutting-edge technology tools. I specialize in crafting impactful projects, with a strong emphasis on Software Development initiatives that I seamlessly deploy to web applications using cloud infrastructure.",
  avatar_image_path: "projects_image.svg",
};

const publicationsHeader = {
  title: "Publications",
  description:
    "I have worked on and published a few research papers and publications of my own.",
  avatar_image_path: "projects_image.svg",
};

const publications = {
  data: [
    // {
    //   id: "MDEwOlJlcG9zaXRvcnkyNDU0NjcyNzQ=",
    //   name: "School Magement System",
    //   createdAt: "2020-03-06T16:26:54Z",
    //   description: "The School Admission System is a web-based  application designed to help school       administrators manage student and teacher data.The data is then stored in a database for easy  access and retrieval. ",
    //   url:
    //     "https://school-manegement-system.vercel.app/",
    // },
    {
      id: "MDEwOlJlcG9zaXRvcnkyNDU0NjcyNzi=",
      name: "Artificial Intelligence Paper",
      createdAt: "2020-03-06T16:26:54Z",
      description: "Pet Finder is a web-based platform that allows  users to find their adoptable pets by location,  breed, age, gender & more. It provides photos,  descriptions, and contact information for the  shelter or rescue handling the adoption. ",
      url:
        "https://www.andrewng.org/publications/building-high-level-features-using-large-scale-unsupervised-learning/",
    },
    {
      id: "MDEwOlJlcG9zaXRvcnkyNDU0NjcyNze=",
      name: "Artificial Intelligence Paper",
      createdAt: "2020-03-06T16:26:54Z",
      description: "Paper Written on Artificial Intelligence published in xyz ",
      url:
        "https://www.andrewng.org/publications/building-high-level-features-using-large-scale-unsupervised-learning/",
    },
    {
      id: "MDEwOlJlcG9zaXRvcnkyNDU0NjcyNzt=",
      name: "Artificial Intelligence Paper",
      createdAt: "2020-03-06T16:26:54Z",
      description: "Paper Written on Artificial Intelligence published in xyz ",
      url:
        "https://www.andrewng.org/publications/building-high-level-features-using-large-scale-unsupervised-learning/",
    },
    {
      id: "MDEwOlJlcG9zaXRvcnkyNDU0NjcyNzb=",
      name: "Artificial Intelligence Paper",
      createdAt: "2020-03-06T16:26:54Z",
      description: "Paper Written on Artificial Intelligence published in xyz ",
      url:
        "https://www.andrewng.org/publications/building-high-level-features-using-large-scale-unsupervised-learning/",
    },
  ],
};

// Contact Page
const contactPageData = {
  contactSection: {
    title: "Contact Me",
    profile_image_path: "profileImge.png",
    description:
      "You can reach out to me via various social media platforms, and I'll respond within 24 hours. I specialize in React.js, JavaScript, HTML5, CSS, Tailwind CSS, Bootstrap, Node.js, Ruby on Rails, PostgreSQL, and MySQL, offering assistance and expertise in these areas. Feel free to message me anytime for support or collaboration opportunities. ",
  },
  addressSection: {
    title: "Address",
    subtitle: "00100, Nairobi, Kenya",
    locality: "Kenyan",
    country: "KE", 
    region: "Nairobi",
    postalCode: "00100", 
    streetAddress: "Umoja 3, Nairobi",
    avatar_image_path: "address_image.svg",
    location_map_link: "https://maps.app.goo.gl/fJTY5TuC24MHhHHX7", // Replace with the correct Google Maps link for your location
  },
  phoneSection: {
    title: "WhatsApp",
    subtitle: "+254712955568",
  },
};

export {
  settings,
  seo,
  greeting,
  socialMediaLinks,
  skills,
  competitiveSites,
  degrees,
  certifications,
  experience,
  projectsHeader,
  publicationsHeader,
  publications,
  contactPageData,
};
